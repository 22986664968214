@font-face {
  font-family: "Siemreap";
  src: url(./fonts/Siemreap/KhmerOS_siemreap.ttf);
}
@font-face {
  font-family: 'Arimo';
  src: url(./fonts/Arimo/Arimo-VariableFont_wght.ttf);
}

body {
  margin: 0;
  padding: 0;
  font-family: "Arimo", "Siemreap", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  overflow-y: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
