/* Hide overflow content in table cmp */
td {
  overflow: hidden;
}

/* Style attach to date-picker */
.MuiPickersToolbarButton-toolbarBtn {
  font-size: 1.4rem;
}

/* When Select component is focused */
.MuiPopover-paper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}
/* Drawer Scroll Bar*/
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #18295c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #496cd6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4262c0;
}
/* .MuiDrawer-paperAnchorLeft::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.MuiDrawer-paperAnchorLeft::-webkit-scrollbar-track {
  background: #18295c;
}

/* Handle */
.MuiDrawer-paperAnchorLeft::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #496cd6;
}

/* Handle on hover */
.MuiDrawer-paperAnchorLeft::-webkit-scrollbar-thumb:hover {
  background: #4262c0;
}
*/

/* Handle arrow in input type number */
/* input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
} */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  /* -webkit-appearance: none; */
  position: absolute;
  cursor: pointer;
  padding: 20px 2px;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
}
/* input[type="number"] {
  outline: none;
  border-right: 0px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 0 px solid black;
  height: 20px;
  padding: 1%;
} */

/* Don't Allow user to select text with this class */
.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

/* Hanlde Autocomplete */
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* React-Slick */

.previewImage .slick-prev {
  left: 0;
  z-index: 100;
}
.previewImage .slick-next {
  z-index: 100;
  right: 0;
}
.previewImage .slick-prev:before,
.previewImage .slick-next:before {
  background-color: white;
  color: #2b4ba9;
  border-radius: 50%;
}
.previewImage .slick-slide {
  position: relative;
  outline: none;
  border: none;
}

.categoriesSliderInCreateOrder .slick-prev {
  left: 0;
  z-index: 100;
}
.categoriesSliderInCreateOrder .slick-next {
  z-index: 100;
  right: 0;
}
.categoriesSliderInCreateOrder .slick-prev:before,
.categoriesSliderInCreateOrder .slick-next:before {
  background-color: white;
  color: #737373;
  border-radius: 50%;
}
.categoriesSliderInCreateOrder .slick-slide {
  position: relative;
  outline: none;
  border: none;
}

/* Date Input Styling */
input[type='date'] {
  padding: 0;
  height: 40px;
}
input[type='date']::-webkit-datetime-edit-text {
  padding: 0 17px;
}
input[type='date']::-webkit-datetime-edit-day-field {
  position: absolute;
  left: -5px;
  top: 0;
}
input[type='date']::-webkit-datetime-edit-month-field {
  position: absolute;
  top: 0;
  left: 33px;
  color: inherit;
  background-color: transparent;
}
input[type='date']::-webkit-datetime-edit-year-field {
  position: absolute;
  top: 0;
  left: 75px;
}
input[type='date']::-webkit-datetime-edit-fields-wrapper {
  pointer-events: none;
  position: absolute;
  left: 25px;
  top: 10px;
  z-index: 1;
}
input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  background: transparent;
  bottom: 0;
  left: -23px;
  right: 0;
  top: -2px;
  color: transparent;
  cursor: pointer;
  height: auto;
  width: auto;
  z-index: 2;
}

/* QL Configuration */
.ql-container {
  min-height: 110px;
}
/* Set content for font-families */
.ql-font-arial,
.ql-font span[data-value='sans-serif']::before {
  font-family: sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value='comic-sans']::before {
  font-family: 'Comic Sans MS', cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value='courier-new']::before {
  font-family: 'Courier New';
}
.ql-font-georgia,
.ql-font span[data-value='georgia']::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value='helvetica']::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value='lucida']::before {
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
}

/* Set content for sizes */
.ql-size-extra-small,
.ql-size span[data-value='extra-small']::before {
  font-size: 13px !important;
}
.ql-size-small,
.ql-size span[data-value='small']::before {
  font-size: 14px !important;
}
.ql-size-medium,
.ql-size span[data-value='medium']::before {
  font-size: 18px !important;
}
.ql-size-large,
.ql-size span[data-value='large']::before {
  font-size: 20px !important;
}

/* set Material UI TreeView background-color */
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover,
.MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
  /* color: none; */
  background-color: transparent;
}

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent;
}

.MuiTreeItem-root:focus {
  background-color: transparent;
}

.MuiTreeItem-label:hover {
  background-color: transparent;
}
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent;
}
